header {
  background-color: $white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  color: $green;
  height: $doublePadding;
  padding: $halfPadding 0;
  z-index: 2;

  @include desktop() {
    box-shadow: none;
    height: 100vh;
    padding: $padding 0;
    position: absolute;
    right: 0;
    top: 0;
    width: $headerWidth;
  }

  .header-inner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    @include desktop() {
      border-right: $borderWidth solid $clay;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  a {
    border: none;
    padding: 5px 15px;
    text-decoration: none;

    @include desktop() {
      order: 3;
      padding: 0;
    }
  }

  svg {
    width: 150px;

    @include desktop() {
      height: calc(80vh - 150px);
      transform: none;
      width: 75px;
    }
  }

  .search-icon {
    box-sizing: content-box;
    cursor: pointer;
    display: none;
    padding: 5px 15px;
    width: $desktopIconWidth;

    @include desktop() {
      display: block;
      margin-bottom: $padding;
      order: 2;
    }
  }

  .logo {
    @include bowtie(30px);
    bottom: 15px;
    display: none;
    position: fixed;
    transform: rotate(90deg);

    @include desktop() {
      display: block;
    }
  }
}
