body {
  font-family: 'Open Sans';
}

h1, h2, h3, h4 {
  font-family: Prospectus, serif;
}

h1 {
  font-size: 1.4rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  @include desktop() {
    font-size: 3rem;
  }
}

h2 {
  font-size: 1.3rem;
  letter-spacing: 0.25em;
  text-transform: uppercase;

  @include desktop() {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.2rem;
  letter-spacing: .05em;

  @include desktop() {
    font-size: 1.5rem;
  }
}

h4 {
  color: $green;
  letter-spacing: 0.25em;
  text-transform: uppercase;
}

a {
  border-bottom: 1px solid $clay;
  color: $green;
  font-family: Prospectus;
  letter-spacing: 0.25em;
  padding-bottom: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

a.non-text-link {
  border: none;
  padding-bottom: 0;
}

.section-copy a:not(.cta) {
  border: none;
  font-family: unset;
  letter-spacing: unset;
  padding-bottom: 0;
  text-decoration: underline;
  text-transform: unset;
}

p, blockquote, li {
  line-height: 1.5em;
}

blockquote {
  color: $green;
  margin: $halfPadding;
  margin-right: 0;
}

.green {
  color: $green;
}
