.cta {
  cursor: pointer;
  display: block;
  font-family: Prospectus, serif;
  letter-spacing: 0.3rem;
  margin: $padding auto;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  width: max-content;

  &-link {
    color: $green;
    border-bottom: 1px solid $clay;
  }

  &-button {
    color: $white;
    position: relative;

    @include bowtie(30px);
  }

  &-button2 {
    color: $white;
    background-color: $clay;
    padding: $halfPadding $padding;
  }

  &-left {
    margin-left: 0;
  }
}
