// To whoever works on this next... sorry. This feels like a bad SCSS file. - NB

footer {
  align-items: center;
  border-top: $borderWidth solid $clay;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: $padding $padding 0;
  padding: $padding 0;

  @include desktop() {
    flex-direction: row;
    margin-left: #{$headerWidth + $padding};
  }

  > *:not(:last-child) {
    margin: 0 0 $halfPadding;

    @include desktop() {
      margin-bottom: 0;
    }
  }

  p {
    color: $gray;
    font-family: Prospectus;
    order: 2;
    text-align: center;

    @include desktop() {
      order: 1;
    }
  }

  a:not(.neighbors-link) {
    order: 1;

    @include desktop() {
      order: 2;
    }
  }

  .neighbors-link {
    order: 3;

    svg {
      fill: $gray;
      width: 300px;
    }
  }
}
