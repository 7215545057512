.subnav {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: $doublePadding;
  width: 100%;

  &--bottom {
    margin-bottom: 0;
    margin-top: $doublePadding;
  }

  a {
    margin: 10px 0;
  }

  @include desktop() {
    align-items: unset;
    flex-direction: row;

    a {
      font-size: 0.75em;
      margin: 0;
    }
  }
}
