$hamburger-breakpoint-mobile   : #{$desktop - 1px} !default;
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 5px !default;
$hamburger-layer-width         : 35px !default;
$hamburger-layer-width-mobile  : 20px !default;
$hamburger-layer-height        : 1px !default;
$hamburger-layer-spacing       : 12px !default;
$hamburger-layer-spacing-mobile : 7px !default;
$hamburger-layer-color         : $black !default;
$hamburger-layer-border-radius : 0 !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spring,
  spring-r,
  stand,
  stand-r,
  spin,
  spin-r,
  squeeze,
  vortex,
  vortex-r
) !default;

@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';

.hamburger {
  outline: none;
  order: 2;

  @include desktop() {
    margin-bottom: $halfPadding;
    order: 1;
  }
}
