.tns-outer {
  display: flex;
  flex-direction: column;

  margin: #{-$padding} #{-$padding} $padding;

  @include desktop() {
    background: linear-gradient(90deg, transparent 55%, $green 45%);
    flex-direction: row;
    margin-left: 0;
    margin: 0 0 $padding;
    padding: $halfPadding $halfPadding $halfPadding 0 !important; // override tiny-slider.scss
  }
}

.tns-slider {
  .tns-item {
    border-bottom: 11px solid $green;
    font-size: 0;
    position: relative;

    @include desktop() {
      border-bottom: 0;
    }
  }

  .tns-border {
    border: 2px solid $clay;
    bottom: #{$padding / 2};
    left: #{$padding / 2};
    position: absolute;
    right: #{$padding / 2};
    top: #{$padding / 2};
    z-index: 1;
  }

  @include desktop() {
    display: flex;
  }

  &__slide {
    position: relative;

    &__border {
      border: 2px solid $green;
      bottom: #{$padding / 2};
      left: #{$padding / 2};
      position: absolute;
      right: #{$padding / 2};
      top: #{$padding / 2};
      z-index: 1;
    }
  }

  img {
    width: 100%;
  }
}

.tns-ovh {
  order: 1;

  @include desktop() {
    flex: 3;
    order: 2;
  }
}

.tns-nav {
  order: 2;

  @include desktop() {
    border-bottom: $borderWidth solid $clay;
    flex: 2;
    margin-bottom: $halfPadding;
    margin-right: $padding;
    order: 1;
    text-align: right;
  }

  button {
    display: inline-block;
    height: 30px;
    outline: none;
    position: relative;
    width: 30px;

    &:before {
      border-radius: 50%;
      border: 1px solid $black;
      content: '';
      height: 10px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
    }

    &.tns-nav-active:before {
      background-color: $black;
    }
  }
}
