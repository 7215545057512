// Colors
$black: #060606;
$clay: #B78B1E;
$green: #006E43;
$stucco: #97A3AE;
$white: #FFFFFF;
$gray: #C4C4C4;

// Typography
$baseFontSize: 16px;

// Spacing
$padding: 30px;
$halfPadding: $padding / 2;
$doublePadding: $padding * 2;
$headerWidth: 120px;
$iconWidth: 20px;
$desktopIconWidth: 35px;
$contentMaxWidth: 900px;
$borderWidth: 2px;

// Breakpoints
$desktop: 1100px;
