.give {
  .modal-body h2 {
    margin-bottom: $halfPadding;
  }

  h2 {
    letter-spacing: 0.05em;
    text-transform: unset;
  }

  .excel {
    margin-right: 0;

    @include desktop() {
      margin-right: unset;
    }

    hr {
      margin-left: 0;
    }
  }

  .excel--title {
    overflow: hidden;
    padding: $padding;
    position: relative;

    @include desktop() {
      padding: $doublePadding;
    }

    &__bg {
      background-image: url('/../../web/assets/images/give.jpg');
      background-position: center;
      background-size: cover;
      filter: brightness(0.7);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    h2 {
      color: white;
      font-size: 2rem;
      margin-bottom: $padding;
      margin-top: 0;
      text-align: center;

      @include desktop() {
        font-size: 4rem;
        margin-bottom: $doublePadding;
      }
    }

    .cta {
      z-index: 1;
    }
  }

  .excel--scripture,
  .excel--description,
  .excel--timeline,
  .excel--pledge,
  .excel--faq {
    margin: #{$doublePadding + 10px} 0;

    @include desktop() {
      margin: #{$doublePadding + 20px} auto;
      max-width: 80%;
    }

    .one-one {
      padding-bottom: 0;
    }
  }

  .excel--scripture {
    border-left: 2px solid $clay;
    color: $clay;
    font-family: Prospectus;
    font-size: 1.3rem;
    padding-left: $padding;

    p {
      margin: 0;
    }

    &__reference {
      font-size: 1.1rem;
      letter-spacing: 0.1em;
      margin-top: $padding !important;
      opacity: 0.75;

      @include desktop() {
        font-size: 1.3rem;
      }
    }

    @include desktop() {
      font-size: 2rem;
      padding-left: $doublePadding;
    }
  }

  .excel--description {
    h3 {
      color: $green;
    }
  }

  .excel--timeline {
    h3 {
      color: $clay;
    }

    &__grid_wrapper {
      overflow: hidden;
      position: relative;
    }

    &__arrow {
      bottom: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 18px;
      z-index: -1;

      svg {
        margin: 0;
      }

      @include desktop() {
        height: 18px;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        width: 100%;
      }
    }

    &__grid {
      display: grid;
      grid-gap: 20px 10px;
      text-align: center;

      grid-template-areas:
        'deep . wide'
        'd1 y1 w1'
        '. . .'
        'd2 y2 w2'
        '. . .'
        'd3 y3 w3'
        '. . .'
        '. . .';
      grid-template-columns: 4fr 2fr 4fr;

      @include desktop() {
        grid-gap: $padding;
        grid-template-areas:
          'wide w1 w2 w3 .'
          '. y1 y2 y3 .'
          'deep d1 d2 d3 .';
        grid-auto-rows: 1fr;
        grid-template-columns: 2fr 4fr 4fr 4fr 1fr;
      }

      h3 {
        margin: 0;
      }

      .year {
        background-color: $white;
        border: 2px solid $green;
        padding: 10px;
      }

      > div {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  .excel--pledge {
    .box {
      border: 2px solid $clay;
      padding: $halfPadding;
    }

    .cta {
      margin: $halfPadding auto;
    }

    &__grid {
      display: grid;
      grid-gap: 10px;
      grid-template-areas:
        'one two'
        'onep ttp';
      grid-template-columns: 1fr 1fr;
      text-align: center;

      p {
        margin: 0 auto;
        max-width: 200px;
      }
    }

    &__mobile {
      p {
        margin: $padding auto 0;
        text-align: center;
        width: 60%;
      }
    }
  }
}

.give-modal__trigger {
  color: $clay;
  font-family: Prospectus;
  cursor: pointer;
  border-bottom: 1px solid $clay;
  padding-bottom: 3px;
  font-size: 1.2em;
  display: inline-block;
  margin-bottom: $halfPadding;
}

.give-modal__contents {
  display: none;
}

.give-modal {
  display: none;

  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 20px;
  left: 50%;
  padding: $padding;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  h2 {
    color: $clay;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: $doublePadding;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__inner {
    border: 2px solid $clay;
    padding: $doublePadding;
  }

  &.open {
    display: block;
  }
}
