.missionary {
  border-top: $borderWidth solid $green;
  padding-top: $padding;

  @include desktop() {
    margin-left: $desktopIconWidth;
  }

  .location {
    margin-top: 0;
  }

  .name {
    margin-bottom: 5px;
  }

  .children {
    font-style: italic;
    margin-bottom: $halfPadding;
    margin-top: 5px;
  }
}
