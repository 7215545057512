section {
  margin: 0 20px $padding #{-$halfPadding};

  @include desktop() {
    margin: 0;
  }

  hr {
    margin-bottom: $padding;
    margin-left: $halfPadding + $iconWidth;
    margin-top: 0;

    @include desktop() {
      margin-left: 0;
    }
  }

  svg {
    flex: 0 0 auto;

    @include desktop() {
      margin-top: 4px;
    }
  }

  .section-wrapper {
    display: flex;
    margin-top: $padding;
  }

  .section-copy {
    padding-left: $halfPadding;

    @include desktop() {
      padding-left: $padding;
    }

    h1, h2 {
      margin-bottom: $halfPadding;

      &:first-child {
        margin-top: 0;
      }
    }

    p:first-child:last-child {
      margin: 0;
    }

    > p img {
      margin-bottom: $halfPadding;
      width: 100%;
    }
  }

  > .image-wrapper {
    margin-left: $halfPadding + $iconWidth;
    width: calc(100% - #{$halfPadding + $iconWidth});

    @include desktop() {
      margin-left: 0;
      width: 100%;
    }
  }
}

.first > .section-wrapper {
  margin-bottom: $halfPadding;
  margin-top: 0;
}
