section form {
  margin-left: 35px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

form {
  font-family: Prospectus;
  max-width: 350px;
  width: 100%;

  label, input {
    display: inline-block;
    height: $padding;
    width: 100%;
  }

  input {
    margin-bottom: $padding;
  }

  label {
    font-style: italic;
  }

  input[type=email],
  input[type=text] {
    border-bottom: 1px solid $green;
  }

  .cta {
    margin-left: 0;
  }
}
