.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &.visible {
    display: block;
  }

  .modal {
    background-color: $white;
    left: 0;
    max-height: 100%;
    overflow-y: scroll;
    padding: $padding;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    @include desktop() {
      left: 50%;
      max-width: 700px;
      transform: translate(-50%, -50%);
    }
  }

  .modal-body {
    > :first-child {
      margin-top: 0;
    }

    > p {
      margin: 0;
    }
  }

  button {
    margin-bottom: $halfPadding;
  }
}
