.image-wrapper {
  font-size: 0;
  position: relative;

  &:before {
    background: url('../../web/assets/images/overlay.jpg');
    background-size: cover;
    content: '';
    height: 100%;
    mix-blend-mode: overlay;
    opacity: .2;
    position: absolute;
    width: 100%;
  }
}
