nav {
  background-color: $white;
  display: flex;
  flex-direction: column;
  transition: right 500ms ease-in-out;
  width: 100%;
  z-index: 3;

  @include desktop() {
    height: 100%;
    flex-direction: row;
    position: fixed;
    right: calc(100% - #{$headerWidth});
    top: 0;
  }

  .nav-inner {
    background-color: $white;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.15);
    height: calc(100vh - #{$doublePadding});
    overflow-y: scroll;
    padding: $padding;
    position: absolute;
    right: 100%;
    top: #{$doublePadding};
    transition: right ease-in-out 500ms;
    width: 100%;
    z-index: 3;

    @include desktop() {
      border-right: $borderWidth solid $clay;
      box-shadow: none;
      margin: $padding 0;
      padding: $padding $doublePadding;
      position: static;
      width: calc(100% - #{$headerWidth});
    }

    > ul {
      padding-left: 0;
    }
  }

  &.open {
    .nav-inner {
      right: 0;
    }

    @include desktop() {
      right: -$borderWidth;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }

  .nav-folder {
    cursor: pointer;
    line-height: 1;
    user-select: none;

    &:not(:last-child) {
      margin-bottom: $halfPadding;
    }

    > span {
      @extend h1;
      letter-spacing: 0.1em;
      text-transform: capitalize;
      position: relative;
    }

    &.nav-folder-link a {
      border-bottom: 0;
      color: $black;
      letter-spacing: unset;
      text-decoration: none;
      text-transform: none;
    }

    &:not(.nav-folder-link) {
      > span:after {
        color: $clay;
        content: '+';
        display: inline-block;
        font-family: sans-serif;
        font-size: 0.75em;
        font-weight: 100;
        margin-left: $halfPadding;
        position: absolute;
        top: 45%;
        transform: rotate(0) translateY(-50%);
        transform-origin: 41% 0;
        transition: transform 250ms;
      }
    }

    &.open > span:after {
      transform: rotate(135deg) translateY(-50%);
    }

    .nav-folder-links {
      display: none;
    }

    &.open .nav-folder-links {
      display: block;
    }
  }

  .nav-folder-links {
    margin-bottom: $padding;
    margin-top: $halfPadding;
    padding-left: $padding;

    li {
      &:not(:last-child) {
        margin-bottom: $halfPadding;
      }

      a {
        @extend h3;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
        padding: 0;
        text-transform: capitalize;

        border-bottom: 0;
        color: $clay;

        &:hover {
          color: $green;
        }
      }
    }
  }
}

.nav-secondary {
  margin: $padding 0;

  li {
    margin: 5px 0;
  }
}

.nav-footer {
  border: 1px solid $clay;
  border-width: 1px 0;
  display: inline-block;
  font-size: 0.75em;
  padding: $halfPadding 0;

  a {
    border: 0;
    color: unset;
    font-family: unset;
    letter-spacing: unset;
    text-decoration: underline;
    text-transform: unset;
  }
}

.nav-search-wrapper {
  padding: $padding 0;

  @include desktop() {
    padding: 0;
  }
}
