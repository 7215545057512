.page-title {
  border-bottom: $borderWidth solid $clay;
  margin-bottom: $padding;
  text-align: center;
  width: 100%;

  @include desktop() {
    text-align: left;
  }

  h1 {
    margin: $halfPadding 0;
  }
}

.content-section {
  max-width: $contentMaxWidth;
  width: 100%;

  @include desktop() {
    margin-left: 0;
    padding-right: 0;
  }

  figure {
    margin: 0;
  }

  li {
    margin: 0.75rem 0;
  }
}

.sermons {
  section {
    margin-bottom: 0;
  }

  section:first-child .section-wrapper {
    margin-top: 0;
  }

  p {
    margin-bottom: $padding;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.sermon-links {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: $halfPadding;

  a:first-child {
    margin-bottom: 10px;

    @include desktop() {
      margin-bottom: $halfPadding;
    }
  }

  &--latest {
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $padding;

    a {
      margin-top: $padding;
    }

    @include desktop() {
      flex-direction: row;

      a {
        margin-top: $halfPadding;
      }

      a:first-child {
        margin-bottom: 0;
        margin-right: $padding;
      }
    }
  }
}

.collapsable.hidden {
  display: none;
}

.staff {
  .grid div {
    h3 {
      margin-bottom: 0;
      margin-top: $halfPadding;
    }

    p {
      margin-top: 0;
    }

    .cta {
      margin-bottom: 0;
      margin-top: $halfPadding;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  .section-copy {
    width: 100%;
  }
}

.contact {
  h3 {
    font-style: normal;
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
  }
}

.search-results ul {
  padding-left: 0;
}
