.sermon-filters-wrapper {
  border-top: $borderWidth solid $clay;
  margin-top: $padding;
  padding: $padding ($padding + $iconWidth);

  h4 {
    margin-top: 0;
  }

  &--no-border {
    border-top: none;
    padding: 0 ($padding + $iconWidth) $padding;
  }
}

.sermon-filters {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include desktop() {
    flex-direction: row;
  }

  .select-wrapper {
    flex: 1;
    max-width: 400px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: $halfPadding;
    }

    @include desktop() {
      margin-bottom: 0;

      &:not(:last-child) select {
        border-right-width: 0;
      }
    }

    &:after {
      content: '▾';
      position: absolute;
      top: 5px;
      right: 12px;
      z-index: 1;
      color: $green;
      line-height: 31px;
      font-size: 18px;
    }
  }

  select {
    appearance: none;
    border: $borderWidth solid $green;
    cursor: pointer;
    display: block;
    padding: 10px;
    padding-right: $padding + 10px;
    position: relative;
    width: 100%;
    z-index: 2;
    -webkit-appearance: none;
  }
}
