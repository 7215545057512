.faq {
  border-bottom: 2px solid $clay;
  padding: $padding 0;

  &__q {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    h3 {
      color: $clay;
      margin: 0;
    }

    svg {
      fill: $clay;
      margin-left: $padding;
      transition: transform 0.5s;
    }
  }

  .faq__a {
    display: none;
    padding-top: $halfPadding;
  }

  &.open {
    .faq__q svg {
      transform: rotate(180deg);
    }

    .faq__a {
      display: block;
    }
  }
}
