// Global (order matters)
@import '../../node_modules/modern-normalize/modern-normalize.css';
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
@import 'global/variables.scss';
@import 'global/mixins.scss';
@import 'global/fonts.scss';
@import 'global/typography.scss';
@import 'global/reset.scss';

// Components (order does not matter)
@import 'components/content.scss';
@import 'components/cta.scss';
@import 'components/faq.scss';
@import 'components/footer.scss';
@import 'components/forms.scss';
@import 'components/give.scss';
@import 'components/hamburger.scss';
@import 'components/header.scss';
@import 'components/home.scss';
@import 'components/image.scss';
@import 'components/layout.scss';
@import 'components/missionary.scss';
@import 'components/modal.scss';
@import 'components/nav.scss';
@import 'components/schedule.scss';
@import 'components/section.scss';
@import 'components/sermons.scss';
@import 'components/slider.scss';
@import 'components/stayConnected.scss';
@import 'components/subnav.scss';

hr {
  background-color: $clay;
  border: 0;
  height: $borderWidth;
}

.mobile-only {
  @include desktop() {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include desktop() {
    display: block;
  }
}
