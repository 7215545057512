html {
  font-family: 'Open Sans', sans-serif;
  font-size: $baseFontSize;
}

body {
  background-color: $white;
  color: $black;

  &.noscroll {
    overflow: hidden;
  }
}

main {
  margin: $padding;

  @include desktop() {
    margin: $padding;
    margin-left: #{$headerWidth + $padding};
  }
}

.one-one {
  @include desktop() {
    display: grid;
    grid-gap: $padding;
    grid-template-columns: 1fr 1fr;
    padding-bottom: $padding;
  }
}

.one-two {
  @include desktop() {
    display: grid;
    grid-gap: $padding;
    grid-template-columns: 1fr 2fr;
    padding-bottom: $padding;
  }
}

.two-one {
  @include desktop() {
    display: grid;
    grid-gap: $padding;
    grid-template-columns: 2fr 1fr;
    padding-bottom: $padding;
  }
}

.one-one-one {
  @include desktop() {
    display: grid;
    grid-gap: $padding;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: $padding;
  }
}

.top-arrow {
  bottom: $padding;
  cursor: pointer;
  position: fixed;
  right: $padding;
}

img {
  width: 100%;
}

.grid {
  display: grid;
  grid-gap: $padding;
  grid-template-columns: 1fr;

  &--two {
    @include desktop() {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--three {
    @include desktop() {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  
  &--four {
    @include desktop() {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}

.grid-item {
  &--two {
    @include desktop() {
      grid-column: 1 / span 2;
    }
  }
}