.schedule {
  border: 2px solid $clay;
  margin: 0 $halfPadding $padding;
  padding: $halfPadding $padding;

  @include desktop() {
    margin: 0 0 $padding 0;
  }

  > div {
    margin: $padding 0;
  }

  > div:last-child {
    margin-bottom: $halfPadding;
  }

  h1, h2 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
    margin-top: 0;
  }
}
