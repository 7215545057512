@mixin desktop() {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin bowtie-pseudo($size) {
  border-left: #{$size / 2} solid transparent;
  border-right: #{$size / 2} solid transparent;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  z-index: -1;
}

@mixin bowtie($size) {
  border: none;
  box-sizing: border-box;
  padding: 0 $size;
  position: relative;

  &:before {
    @include bowtie-pseudo($size);
    border-bottom: $size solid $green;
    top: 0;
  }

  &:after {
    @include bowtie-pseudo($size);
    border-top: $size solid $green;
    bottom: 0;
  }
}
